export const setCookieOpenId = (openId) => {
    sessionStorage.setItem('open_id', openId);
};
export const getCookieOpenId = function() {
    return sessionStorage.getItem('open_id');
};
export const setCookieToten = (data) => {
    sessionStorage.setItem('access_token', data.access_token);
    sessionStorage.setItem('refresh_token', data.refresh_token);
};
export const setCookieAccessToten = (access_token) => {
    sessionStorage.setItem('access_token', access_token);
};
export const getCookieAccessToken = function() {
    return sessionStorage.getItem('access_token');
};
// 存储 管理员权限
export const setCookiebtmAdminFlag = (btmAdminFlag) => {
    sessionStorage.setItem('btmAdminFlag', btmAdminFlag);
};
// 获取 管理员权限
export const getCookiebtmAdminFlag = function() {
    return sessionStorage.getItem('btmAdminFlag');
};
export const setCookiePatient = (data) => {
    localStorage.setItem('patient_id', data.patientId);
    localStorage.setItem('patient_code', data.patientCode);
    localStorage.setItem('patient_name', data.patientName);
    localStorage.setItem('patient_phone', data.patientPhone);
    localStorage.setItem('patient_headImg', data.headImg);
};
export const getCookieDid = function() {
    return localStorage.getItem('patient_id');
};
export const getCookieDcode = function() {
    return localStorage.getItem('patient_code');
};
export const getCookieDname = function() {
    return localStorage.getItem('patient_name');
};
export const getCookieDphone = function() {
    return localStorage.getItem('patient_phone');
};
export const getCookieDimg = function() {
    return localStorage.getItem('patient_headImg');
};
// 判断是否已经登录过
export const hasLogin = function() {
    return sessionStorage.getItem('access_token') !== null && sessionStorage.getItem('access_token') !== undefined
};
// 退出登录 清空数据
export const LoginOut = function () {
    return sessionStorage.clear();
};
